/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import PageHeader from '../components/pageheader';
import SEO from '../components/seo';
import Button from '../components/ui/button';
import Heading from '../components/ui/Heading';
import Text from '../components/ui/Text';
import Header from '../containers/Layout/Header';
import { theme } from '../theme';

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${theme.spacings.xxl};
  text-align: center;
`;

const NotFoundPage = ({ pageContext, location }) => {
  return (
    <ThemeProvider theme={theme}>
      <SEO title="Página não encontrada" />
      <Header />
      <PageHeader pageContext={pageContext} location={location} title="404" />
      <NotFoundContainer>
        <Heading mb="24px">Desculpe, página não encontrada!</Heading>
        <Text fontSize="lg" mb="24px">
          A página que você está procurando não existe ou está temporariamente indisponível.
        </Text>
        <Button to="/">Voltar à página principal</Button>
      </NotFoundContainer>
    </ThemeProvider>
  );
};

NotFoundPage.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default NotFoundPage;
